import React, { useEffect, useState, useLayoutEffect } from "react";
import {
	IonContent,
	IonPage,
	IonIcon,
	IonImg,
	IonInput,
	IonGrid,
	IonRow,
	IonCol,
	IonItem,
	IonButton,
	IonSegment,
	IonSegmentButton,
	useIonToast,
	useIonAlert,
	IonList,
	IonText,
	IonSelect,
	IonSelectOption,
	useIonViewDidEnter,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import {
	getAutoBankList,
	autoBankDeposit,
	depositHistory,
	getUserBanks,
	withdrawBank,
	withdrawHistory,
	getUserInfo,
	selectAutoBankRequest,
	getCompanyBanks,
	depositBank,
	applyGiftCode,
	applyGiftCodeDetail,
	getGiftCodeByCodeName,
	cardDeposit,
	depositUsdt,
	withdrawUsdt,
	betCheckingProcessList,
} from "../redux/auth/authCrud";
import { useSelector, shallowEqual } from "react-redux";
import { defaultsDeep, toNumber, orderBy, get, toUpper, size, subtract } from "lodash";
import moment from "moment";
import axios from "axios";
import { RouteComponentProps } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as auth from "../redux/auth/authRedux";
import { useDispatch } from "react-redux";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { Clipboard } from "@capacitor/clipboard";
import ProgressBar from "@ramonak/react-progress-bar";

import "./DepositWithdraw.css";
import { formatMoney, formatMoneyColor, formatUUID, generateCode } from "../util";
import { API_URL, IMG_MB } from "../constants";
import { eye } from "ionicons/icons";

const INIT_DEPOSIT = {
	type: "bank",
	bank_type: "",
	amount: "",
	callback: `${API_URL}api/AutoBankCallback`,
	card_telco: "",
	card_serial: "",
	card_code: "",
};

const INIT_WITHDRAW = {
	WithdrawType: 0,
	BankName: "",
	AccountName: "",
	AccountNumber: "",
	Amount: "",
	WithdrawPassword: "",
	IndexBank: "0",
};

interface DepositProps
	extends RouteComponentProps<{
		tab: string;
	}> {}

const Deposit: React.FC<DepositProps> = ({ match }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { tab } = match.params;

	const [typeWithdrawal, setTypeWithdrawal] = useState("bank");
	const [dataWithdraw, setDataWithdraw] = useState(INIT_WITHDRAW);
	const [dataWithdrawtHistory, setDataWithdrawHistory] = useState<any>([]);
	const [userBanks, setUserBanks] = useState<any>(null);
	const [usdtDeposit, setUsdtDeposit] = useState<any>(null);
	const [giftCodeName, setGiftCodeName] = useState("");
	const [infoGiftCode, setInfoGiftCode] = useState<any>(null);
	const [dataBetCheckingProcessList, setBetCheckingProcessList] = useState<any>(null);

	// const [autoDepositBanks, setAutodepositBanks] = useState([]);
	const [companyBanks, setCompanyBanks] = useState([]);
	const [dataDepositHistory, setDataDepositHistory] = useState<any>([]);
	const [dataDeposit, setDataDeposit] = useState<any>(INIT_DEPOSIT);
	const [tabDeposit, setTabDeposit] = useState(toNumber(tab));
	const [bankDeposit, setBankDeposit] = useState<any>(null);
	const [chooseDepositHistory, setChooseDepositHistory] = useState<any>(null);
	const [isShowPass, setIsShowPass] = useState(false);
	const [isWithdrawal, setIsWithdrawal] = useState(true);
	const [present] = useIonToast();
	const [presentAlert] = useIonAlert();
	const { userInfo } = useSelector(
		(state: any) => ({
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const _getGiftCodeByCodeName = async () => {
		if (!giftCodeName)
			return presentAlert({
				header: "Thông Báo",
				message: "Nhập tên Giftcode.",
				buttons: ["OK"],
				cssClass: "alert-custom",
			});

		const res = await getGiftCodeByCodeName(giftCodeName);

		if (res.data) {
			setInfoGiftCode(res.data);
		}
	};

	const _applyGiftCode = async () => {
		if (!giftCodeName)
			return presentAlert({
				header: "Thông Báo",
				message: "Nhập tên Giftcode.",
				buttons: ["OK"],
				cssClass: "alert-custom",
			});
		const resGC = infoGiftCode?.SubType === 1 ? await applyGiftCodeDetail(giftCodeName) : await applyGiftCode(giftCodeName);
		if (resGC?.data && resGC?.data?.error && resGC?.data?.error?.message)
			return presentAlert({
				header: "Thông Báo",
				message: resGC?.data?.error?.message,
				buttons: ["OK"],
				cssClass: "alert-custom",
			});

		setInfoGiftCode(null);
		setGiftCodeName("");

		getUserInfo().then((resUser) => {
			dispatch(auth.actions.fulfillUser(get(resUser, "data", null)));
		});

		presentAlert({
			header: "Nhập Giftcode thành công.",
			message: `<span class="text-2a5b84">Vibet88 chúc Quý khách thật nhiều may mắn!!</span> <br/> <span class="text-red">Tham gia các chương trình nhận Hồng Bao tại mục "Khuyến mãi" và giới thiệu bạn bè để nhận thêm những Phần Thưởng hấp dẫn khác.</span>`,
			buttons: ["OK"],
			cssClass: "alert-custom",
		});
	};

	const writeToClipboard = async (url: any, message: any) => {
		await Clipboard.write({
			string: url,
		});
		presentToast("success", message);
	};

	const WithdrawHistory = async () => {
		const res = await withdrawHistory();
		if (res && res.data) {
			setDataWithdrawHistory(orderBy(res.data, ["CreatedTime"], ["desc"]));
		}
	};

	const BetCheckingProcessList = async () => {
		const params = {};
		const res = await betCheckingProcessList(params);
		if (res && res.data) {
			setBetCheckingProcessList(res.data);
		}
	};

	const GetUserBanks = async () => {
		const { data = [] } = await getUserBanks();
		if (data) {
			setUserBanks(data);
			setDataWithdraw(
				defaultsDeep(
					{
						BankName: get(data, `[0].BankName`, ""),
						AccountName: get(data, `[0].AccountName`, ""),
						AccountNumber: get(data, `[0].AccountNumber`, ""),
					},
					dataWithdraw
				)
			);
		}
	};

	const WithdrawBank = async () => {
		if (!userInfo)
			return presentAlert({
				header: "Thông Báo",
				message: "Vui lòng đăng nhập!",
				buttons: ["OK"],
				cssClass: "alert-custom",
			});

		if (!isWithdrawal) return presentToast("error", "Bạn cần hoàn thành đủ Vòng Cược mới có thể rút tiền.");
		if (toNumber(dataWithdraw.Amount) < 200000) return presentToast("error", "Số tiền cần rút từ 200k.");
		if (!dataWithdraw.Amount) return presentToast("error", "Nhập số tiền bạn muốn rút");
		if (!dataWithdraw.WithdrawPassword) return presentToast("error", "Nhập mã rút tiền.");

		if (typeWithdrawal === "bank" && !dataWithdraw.BankName) return presentToast("error", "Chọn ngân hàng nhận tiền");
		if (typeWithdrawal === "bank" && !dataWithdraw.BankName) return presentToast("error", "Chọn ngân hàng nhận tiền");
		if (typeWithdrawal === "usdt" && !userInfo?.UsdtWallet && !dataWithdraw.AccountNumber) return presentToast("error", "Nhập địa chỉ ví USDT của bạn.");

		const resWD =
			typeWithdrawal === "usdt"
				? await withdrawUsdt(
						defaultsDeep(
							{
								Amount: Math.floor(toNumber(dataWithdraw.Amount) / 1000),
								AccountNumber: userInfo?.UsdtWallet ? userInfo?.UsdtWallet : dataWithdraw.AccountNumber,
							},
							dataWithdraw
						)
				  )
				: await withdrawBank(defaultsDeep({ Amount: Math.floor(toNumber(dataWithdraw.Amount) / 1000) }, dataWithdraw));

		if (resWD?.data && resWD?.data?.error && resWD?.data?.error?.message) return presentToast("error", resWD?.data?.error?.message);
		presentAlert({
			header: "Thông Báo",
			message: "Tạo lệnh rút tiền thành công!",
			buttons: ["OK"],
			cssClass: "alert-custom",
		});
		WithdrawHistory();
		setDataWithdraw(INIT_WITHDRAW);
		getUserInfo().then((resUser) => {
			dispatch(auth.actions.fulfillUser(get(resUser, "data", null)));
		});
	};

	const DepositHistory = async () => {
		const res = await depositHistory();
		if (res && res.data) {
			setDataDepositHistory(orderBy(res.data, ["CreatedTime"], ["desc"]));
		}
	};

	const GetDataBanks = async () => {
		const { data } = await getAutoBankList();

		if (data && data.data) {
			// setAutodepositBanks(data.data);
		}
	};

	const GetCompanyBanks = async () => {
		const { data }: any = await getCompanyBanks();

		if (data) {
			setCompanyBanks(data);
		}
	};

	const agenerateQrCode = async (data: any) => {
		const resQr = await axios({
			method: "post",
			url: "https://api.vietqr.io/v2/generate",
			data: {
				accountNo: data?.AccountNumber,
				accountName: data?.AccountName,
				acqId: data?.QRCodeNumber,
				amount: data?.amount,
				addInfo: toUpper(data?.code),
				format: "text",
				template: "compact",
			},
		});

		if (resQr.data && resQr.data.data) {
			setBankDeposit(defaultsDeep({ note: toUpper(data?.code) }, resQr.data.data));
		}
	};

	const _depositAutoBank = async () => {
		if (!userInfo)
			return presentAlert({
				header: "Thông Báo",
				message: "Vui lòng đăng nhập!",
				buttons: ["OK"],
				cssClass: "alert-custom",
			});

		if (!dataDeposit.amount) return presentToast("error", "Nhập số tiền bạn muốn nạp");
		const newAmount = dataDeposit?.amount - (dataDeposit?.amount % 1000);
		if (toNumber(newAmount) < 100000) return presentToast("error", "Số tiền cần nạp từ 100k.");
		if (dataDeposit?.type === "manual" && !dataDeposit.bank_type) return presentToast("error", "Chọn ngân hàng nạp tiền");
		if (dataDeposit?.type === "card" && !dataDeposit.card_telco) return presentToast("error", "Chọn nhà mạng nạp thẻ cào");
		if (dataDeposit?.type === "card" && !dataDeposit.card_code) return presentToast("error", "Nhập mã thẻ trên thẻ cào");
		if (dataDeposit?.type === "card" && !dataDeposit.card_serial) return presentToast("error", "Nhập số seri trên thẻ cào");

		if (dataDeposit?.type === "usdt") {
			const resUsdt = await depositUsdt(defaultsDeep({ amount: Math.floor(toNumber(newAmount) / 1000) }, dataDeposit));
			if (resUsdt?.data?.Data) {
				setUsdtDeposit(resUsdt?.data?.Data);
			}
			if (resUsdt?.data && resUsdt?.data?.error && resUsdt?.data?.error?.message) return presentToast("error", resUsdt?.data?.error?.message);
			return;
		}

		if (dataDeposit?.type === "manual") {
			const codeDeposit = generateCode();
			const resData = await depositBank(defaultsDeep({ note: toUpper(codeDeposit), amount: Math.floor(toNumber(newAmount) / 1000) }, dataDeposit));
			if (resData?.data && resData?.data?.error && resData?.data?.error?.message) {
				if (resData?.data?.data) {
					setBankDeposit(null);
					const tempDeposit = {
						type: "manual",
						amount: resData?.data?.data?.Amount * 1000,
						bank_type: resData?.data?.data?.BankCode,
						BankName: resData?.data?.data?.BankName,
						AccountName: resData?.data?.data?.AccountName,
						AccountNumber: resData?.data?.data?.AccountNumber,
						BankCode: resData?.data?.data?.BankCode,
						QRCodeNumber: resData?.data?.data?.QRCodeNumber,
						callback: `${API_URL}api/AutoBankCallback`,
					};

					setDataDeposit(tempDeposit);
					agenerateQrCode(defaultsDeep({ code: resData?.data?.data?.Note }, tempDeposit));
				}
				presentToast("error", resData?.data?.error?.message);
				return;
			}
			agenerateQrCode(defaultsDeep({ code: codeDeposit, amount: newAmount }, dataDeposit));
			return;
		}

		const res = await autoBankDeposit(defaultsDeep({ amount: newAmount }, dataDeposit));
		if (res && res.data) {
			setBankDeposit(res.data);
		}

		if (res && res.data && res.data.ref_id) {
			let newData: any = null;
			let timerId: any = setInterval(async () => {
				if (newData) {
					if (newData.err_code === 1) {
						presentToast("error", newData.err_msg);
					}

					if (newData.err_code === 0) {
						presentAlert({
							header: "Thông Báo",
							message: "Nạp tiền thành công.",
							buttons: ["OK"],
							cssClass: "alert-custom",
						});
						getUserInfo().then((resUser) => {
							dispatch(auth.actions.fulfillUser(get(resUser, "data", null)));
						});
					}
					return clearInterval(timerId);
				}

				const resRequest = await selectAutoBankRequest(res.data.ref_id);
				if (resRequest && resRequest.data && !resRequest.data.error) {
					newData = resRequest.data;
				}
			}, 5000);

			setTimeout(() => {
				clearInterval(timerId);
			}, 1000 * 60 * 10);
		}
	};

	const _cardDeposit = async () => {
		if (!userInfo)
			return presentAlert({
				header: "Thông Báo",
				message: "Vui lòng đăng nhập!",
				buttons: ["OK"],
				cssClass: "alert-custom",
			});

		if (!dataDeposit.amount) return presentToast("error", "Nhập số tiền bạn muốn nạp");
		const newAmount = dataDeposit?.amount - (dataDeposit?.amount % 1000);
		if (toNumber(newAmount) < 100000) return presentToast("error", "Số tiền cần nạp từ 100k.");
		if (dataDeposit?.type === "card" && !dataDeposit.card_telco) return presentToast("error", "Chọn nhà mạng nạp thẻ cào");
		if (dataDeposit?.type === "card" && !dataDeposit.card_code) return presentToast("error", "Nhập mã thẻ trên thẻ cào");
		if (dataDeposit?.type === "card" && !dataDeposit.card_serial) return presentToast("error", "Nhập số seri trên thẻ cào");

		const res = await cardDeposit(defaultsDeep({ amount: newAmount }, dataDeposit));
		if (res?.data && res?.data?.error && res?.data?.error?.message) return presentToast("error", res?.data?.error?.message);

		presentAlert({
			header: "Thông Báo",
			message: "Nhập thẻ cào thành công.",
			buttons: ["OK"],
			cssClass: "alert-custom",
		});
	};

	useIonViewDidEnter(() => {
		GetDataBanks();
		GetCompanyBanks();
		DepositHistory();
		GetUserBanks();
		WithdrawHistory();
		BetCheckingProcessList();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setTabDeposit(toNumber(tab));
	}, [tab]);

	useEffect(() => {
		if (tabDeposit === 2 && userBanks && userBanks.length === 0) {
			history.push("/account-add-bank");
		}
	}, [tabDeposit, userBanks, history]);

	useLayoutEffect(() => {
		if (!userInfo) {
			window.location.href = "/home?login=true";
		}
	}, [userInfo, history]);

	if (!userInfo) return <IonImg className="page-loading" src={`${IMG_MB}assets/v2/loading.webp`} />;

	return (
		<IonPage className="app-deposit">
			<div className="app-home homepage ion-content deposit">
				<HeaderHome />
				<div className="segment-banks">
					<IonSegment className="ion-segment tab-header  ion-untouched ion-pristine ion-valid sc-ion-segment-md-h sc-ion-segment-md-s md hydrated" mode="md">
						<IonSegmentButton
							mode="md"
							value="naptien"
							className={`ion-segment-button md segment-button-layout-icon-top ion-activatable ion-activatable-instant hydrated ${
								tabDeposit === 0 ? "segment-button-checked" : ""
							}`}
							onClick={() => setTabDeposit(0)}
						>
							Nạp tiền
						</IonSegmentButton>
						<IonSegmentButton
							mode="md"
							value="lichsunaptien"
							className={`ion-segment-button md segment-button-layout-icon-top ion-activatable ion-activatable-instant hydrated ${
								tabDeposit === 1 ? "segment-button-checked" : ""
							}`}
							onClick={() => setTabDeposit(1)}
						>
							Lịch sử nạp tiền
						</IonSegmentButton>
						<IonSegmentButton
							mode="md"
							value="ruttien"
							className={`ion-segment-button md segment-button-layout-icon-top ion-activatable ion-activatable-instant hydrated ${
								tabDeposit === 2 ? "segment-button-checked" : ""
							}`}
							onClick={() => setTabDeposit(2)}
						>
							Rút tiền
						</IonSegmentButton>
						<IonSegmentButton
							mode="md"
							value="lichsuruttien"
							className={`ion-segment-button md segment-button-layout-icon-top ion-activatable ion-activatable-instant hydrated ${
								tabDeposit === 3 ? "segment-button-checked" : ""
							}`}
							onClick={() => setTabDeposit(3)}
						>
							Lịch sử rút tiền
						</IonSegmentButton>
					</IonSegment>
				</div>
				{(tabDeposit === 0 || tabDeposit === 2) && (
					<div className="btn-group pt-5">
						<IonButton className="btn-sic" size="small" onClick={() => history.push("/account")}>
							<IonIcon color="#000000" icon={arrowBack}></IonIcon>
						</IonButton>
						<IonButton className="btn-sic" size="small">
							{tabDeposit === 0 ? `Nạp Tiền` : `Rút Tiền`}
						</IonButton>
					</div>
				)}
				{tabDeposit !== 0 && tabDeposit !== 2 && <QuickOptions />}
				{tabDeposit !== 0 && tabDeposit !== 2 && (
					<div className="btn-group pt-5">
						<IonButton className="btn-sic" size="small" onClick={() => history.push("/account")}>
							<IonIcon color="#000000" icon={arrowBack}></IonIcon>
						</IonButton>
						<IonButton className="btn-sic" size="small">
							{tabDeposit === 1 ? `Lịch Sử Nạp Tiền` : `Lịch Sử Rút Tiền`}
						</IonButton>
					</div>
				)}
			</div>
			<IonContent className="deposit ios overscroll hydrated ion-content">
				<div className="ng-star-inserted">
					{tabDeposit === 0 && (
						<>
							<div className="sic-line">
								<hr />
							</div>
							<div className="btn-group">
								<IonButton
									className={`btn-sic btn-tab-naptien ${dataDeposit?.type === "bank" ? `active` : ``}`}
									size="small"
									onClick={() => {
										setDataDeposit(
											defaultsDeep(
												{
													type: "bank",
												},
												dataDeposit
											)
										);
										setBankDeposit(null);
										setUsdtDeposit(null);
									}}
								>
									Chuyển Khoản Nhanh
								</IonButton>
								<IonButton
									className={`btn-sic btn-tab-naptien ${dataDeposit?.type === "usdt" ? `active` : ``}`}
									size="small"
									onClick={() => {
										setDataDeposit(
											defaultsDeep(
												{
													type: "usdt",
												},
												dataDeposit
											)
										);
										setBankDeposit(null);
										setUsdtDeposit(null);
									}}
								>
									<IonImg className="w-22 pr-5" slot="start" src={`${IMG_MB}assets/icon/icon-usdt.png`}></IonImg>
									USDT
								</IonButton>
								{/* <IonButton
									className={`btn-sic btn-tab-naptien ${dataDeposit?.type === "manual" ? `active` : ``}`}
									size="small"
									onClick={() => {
										setDataDeposit(
											defaultsDeep(
												{
													type: "manual",
													DepositType: 1,
												},
												dataDeposit
											)
										);
										setBankDeposit(null);
										setUsdtDeposit(null);
									}}
								>
									Chuyển Khoản
								</IonButton> */}
								<IonButton
									className={`btn-sic btn-tab-naptien ${dataDeposit?.type === "giftcode" ? `active` : ``}`}
									size="small"
									onClick={() => {
										setDataDeposit(
											defaultsDeep(
												{
													type: "giftcode",
												},
												dataDeposit
											)
										);
										setBankDeposit(null);
										setUsdtDeposit(null);
									}}
								>
									<IonImg className="w-22 pr-5" slot="start" src={`${IMG_MB}assets/icon/icon-giftbox.png`}></IonImg>
									GIFTCODE
								</IonButton>
								{/* <IonButton
									className={`btn-sic btn-tab-naptien ${dataDeposit?.type === "momo" ? `active` : ``}`}
									size="small"
									onClick={() => {
										setDataDeposit(
											defaultsDeep(
												{
													type: "momo",
												},
												dataDeposit
											)
										);
										setBankDeposit(null);
										setUsdtDeposit(null);
									}}
								>
									<IonImg className="w-22 pr-5" slot="start" src={`${IMG_MB}assets/icon/icon-momo.png`}></IonImg>
									Momo
								</IonButton> */}
								<IonButton
									className={`btn-sic btn-tab-naptien ${dataDeposit?.type === "zalo" ? `active` : ``}`}
									size="small"
									onClick={() => {
										setDataDeposit(
											defaultsDeep(
												{
													type: "zalo",
												},
												dataDeposit
											)
										);
										setBankDeposit(null);
										setUsdtDeposit(null);
									}}
								>
									<IonImg className="w-26 pr-5" slot="start" src={`${IMG_MB}assets/icon/icon-zalo.png`}></IonImg>
									Zalo Pay
								</IonButton>
								<IonButton
									className={`btn-sic btn-tab-naptien ${dataDeposit?.type === "ViettelPay" ? `active` : ``}`}
									size="small"
									onClick={() => {
										setDataDeposit(
											defaultsDeep(
												{
													type: "ViettelPay",
												},
												dataDeposit
											)
										);
										setBankDeposit(null);
										setUsdtDeposit(null);
									}}
								>
									<IonImg className="w-30 pr-5" slot="start" src={`${IMG_MB}assets/icon/icon-viettel-pay.png`}></IonImg>
									Viettel Pay
								</IonButton>
								{/* <IonButton
									className={`btn-sic btn-tab-naptien ${dataDeposit?.type === "card" ? `active` : ``}`}
									size="small"
									onClick={() => {
										setDataDeposit(
											defaultsDeep(
												{
													type: "card",
												},
												dataDeposit
											)
										);
										setBankDeposit(null);
										setUsdtDeposit(null);
									}}
								>
									<IonImg className="w-35 pr-5" slot="start" src={`${IMG_MB}assets/icon/icon-thecao.png`}></IonImg>
									Thẻ Cào ĐT
								</IonButton> */}
							</div>
							<IonGrid className="ion-grid ng-star-inserted ios hydrated">
								<IonRow className="ios hydrated">
									{/* {dataDeposit?.type === "bank" && (
										<>
											<IonCol size="12" className="ng-star-inserted ios hydrated">
												<div className="withdrawal__text--account">Chọn Ngân Hàng</div>
											</IonCol>
											{autoDepositBanks.length > 0 &&
												autoDepositBanks.map((bank: any, index: any) => {
													return (
														<IonCol
															size="4"
															className="ng-star-inserted ios hydrated"
															key={index}
															onClick={() => {
																setDataDeposit(
																	defaultsDeep(
																		{
																			bank_type: bank?.bankCode,
																		},
																		dataDeposit
																	)
																);
																setBankDeposit(null);
																setUsdtDeposit(null);
															}}
														>
															<div _nghost-wcs-c5="">
																<IonImg
																	className={`bank-item ng-star-inserted ios hydrated ${dataDeposit.bank_type === bank.bankCode ? "active" : ""}`}
																	src={`${IMG_MB}assets/banks/${bank?.bankCode}.png`}
																></IonImg>
															</div>
														</IonCol>
													);
												})}
										</>
									)} */}
									{dataDeposit?.type === "manual" && (
										<>
											<IonCol size="12" className="ng-star-inserted ios hydrated">
												<div className="withdrawal__text--account">Chọn Ngân Hàng</div>
											</IonCol>
											{companyBanks.length > 0 &&
												companyBanks.map((bank: any, index: any) => {
													if (bank?.AccountNumber === "6364407824735") return <React.Fragment key={index}></React.Fragment>;
													if (!bank?.isActive) return <React.Fragment key={index}></React.Fragment>;
													return (
														<IonCol
															size="4"
															className="ng-star-inserted ios hydrated"
															key={index}
															onClick={() => {
																setDataDeposit(
																	defaultsDeep(
																		{
																			bank_type: bank?.BankCode,
																			BankName: bank?.BankName,
																			AccountName: bank?.AccountName,
																			AccountNumber: bank?.AccountNumber,
																			BankCode: bank?.BankCode,
																			QRCodeNumber: bank?.QRCodeNumber,
																		},
																		dataDeposit
																	)
																);
																setBankDeposit(null);
																setUsdtDeposit(null);
															}}
														>
															<div _nghost-wcs-c5="">
																<IonImg
																	className={`bank-item ng-star-inserted ios hydrated ${dataDeposit.bank_type === bank.BankCode ? "active" : ""}`}
																	src={bank?.imageUrl}
																></IonImg>
															</div>
														</IonCol>
													);
												})}
										</>
									)}
									{dataDeposit?.type === "card" && (
										<>
											<IonCol size="12" className="ng-star-inserted ios hydrated">
												<div className="withdrawal__text--account">Chọn Nhà Mạng</div>
											</IonCol>
											<IonCol
												size="4"
												className="ng-star-inserted ios hydrated"
												onClick={() => {
													setDataDeposit(
														defaultsDeep(
															{
																card_telco: "1",
															},
															dataDeposit
														)
													);
												}}
											>
												<div _nghost-wcs-c5="">
													<IonImg
														className={`bank-item ng-star-inserted ios hydrated ${dataDeposit.card_telco === "1" ? "active" : ""}`}
														src={`${IMG_MB}assets/icon/nap-viettel.png`}
													></IonImg>
												</div>
											</IonCol>
											<IonCol
												size="4"
												className="ng-star-inserted ios hydrated"
												onClick={() => {
													setDataDeposit(
														defaultsDeep(
															{
																card_telco: "3",
															},
															dataDeposit
														)
													);
												}}
											>
												<div _nghost-wcs-c5="">
													<IonImg
														className={`bank-item ng-star-inserted ios hydrated ${dataDeposit.card_telco === "3" ? "active" : ""}`}
														src={`${IMG_MB}assets/icon/nap-mobi.png`}
													></IonImg>
												</div>
											</IonCol>
											<IonCol
												size="4"
												className="ng-star-inserted ios hydrated"
												onClick={() => {
													setDataDeposit(
														defaultsDeep(
															{
																card_telco: "2",
															},
															dataDeposit
														)
													);
												}}
											>
												<div _nghost-wcs-c5="">
													<IonImg
														className={`bank-item ng-star-inserted ios hydrated ${dataDeposit.card_telco === "2" ? "active" : ""}`}
														src={`${IMG_MB}assets/icon/nap-vina.png`}
													></IonImg>
												</div>
											</IonCol>
										</>
									)}
									{dataDeposit?.type === "card" && (
										<>
											<div className="note-small pt-10 pb-5">
												<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
													(Vui lòng chọn đúng nhà mạng và mệnh giá thẻ cào.
													<br />
													Nếu chọn sai sẽ mất thẻ)
												</IonText>
											</div>
											<IonCol size="12" className="ion-col af-wrapper ng-star-inserted ios hydrated">
												<div className="withdrawal__text--account pb-5">Mệnh Giá Thẻ Nạp</div>
												<div className="withdrawal__main--block2">
													<IonItem className="withdrawal__main--item item-interactive item-input item-has-placeholder item ios ion-focusable hydrated ion-untouched ion-pristine ion-valid">
														{/* <IonInput
														inputmode="decimal"
														pattern="[0-9]*"
														placeholder=""
														type="text"
														className="ion-input  sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
														value={formatMoney(toNumber(dataDeposit?.amount))}
														onIonChange={(e: any) => {
															e.detail.value = e.detail.value.replace(/[^0-9]/g, "");
															setDataDeposit(
																defaultsDeep(
																	{
																		amount: toNumber(e.detail.value),
																	},
																	dataDeposit
																)
															);
														}}
													></IonInput>
													<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
														VNĐ
													</IonText> */}
														<IonSelect
															className="ion-select w-full"
															cancelText="Huỷ"
															mode="ios"
															okText="Chọn"
															role="combobox"
															aria-haspopup="dialog"
															aria-expanded="false"
															aria-labelledby="ion-sel-19-lbl"
															value={dataDeposit?.amount}
															onIonChange={(e) => {
																setDataDeposit(
																	defaultsDeep(
																		{
																			amount: e.detail.value,
																		},
																		dataDeposit
																	)
																);
															}}
														>
															<IonSelectOption value="" role="option" id="ion-selopt-441" className="ios hydrated">
																Chọn
															</IonSelectOption>
															<IonSelectOption value={50000} role="option" id="ion-selopt-441" className="ios hydrated">
																50.000
															</IonSelectOption>
															<IonSelectOption value={100000} role="option" id="ion-selopt-441" className="ios hydrated">
																100.000
															</IonSelectOption>
															<IonSelectOption value={200000} role="option" id="ion-selopt-441" className="ios hydrated">
																200.000
															</IonSelectOption>
															<IonSelectOption value={300000} role="option" id="ion-selopt-441" className="ios hydrated">
																300.000
															</IonSelectOption>
															<IonSelectOption value={500000} role="option" id="ion-selopt-441" className="ios hydrated">
																500.000
															</IonSelectOption>
														</IonSelect>
													</IonItem>
													<div className="note-small pt-5">
														<small>
															Quy đổi:{" "}
															<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
																1,000
															</IonText>{" "}
															VNĐ ={" "}
															<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
																1
															</IonText>{" "}
															điểm
														</small>
													</div>
													<div className="note-small pt-5">
														<small>
															Số điểm thực nhận (
															<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
																{toNumber(dataDeposit?.amount) > 1000
																	? formatMoney(Math.floor(toNumber(dataDeposit?.amount) / 1000) - Math.floor(toNumber(dataDeposit?.amount) / 1000) * 0.19)
																	: 0}
															</IonText>
															)
														</small>
													</div>
												</div>
											</IonCol>
											<IonCol size="12" className="ion-col af-wrapper ng-star-inserted ios hydrated">
												<div className="withdrawal__text--account pb-5">Mã Số Thẻ</div>
												<div className="withdrawal__main--block2">
													<IonItem className="withdrawal__main--item item-interactive item-input item-has-placeholder item ios ion-focusable hydrated ion-untouched ion-pristine ion-valid">
														<IonInput
															inputmode="decimal"
															pattern="[0-9]*"
															placeholder=""
															type="text"
															className="ion-input  sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
															value={dataDeposit?.card_code}
															onIonChange={(e: any) => {
																e.detail.value = e.detail.value.replace(/[^0-9]/g, "");
																setDataDeposit(
																	defaultsDeep(
																		{
																			card_code: e.detail.value,
																		},
																		dataDeposit
																	)
																);
															}}
														></IonInput>
													</IonItem>
												</div>
											</IonCol>
											<IonCol size="12" className="ion-col af-wrapper ng-star-inserted ios hydrated">
												<div className="withdrawal__text--account pb-5">Số Seri</div>
												<div className="withdrawal__main--block2">
													<IonItem className="withdrawal__main--item item-interactive item-input item-has-placeholder item ios ion-focusable hydrated ion-untouched ion-pristine ion-valid">
														<IonInput
															inputmode="decimal"
															pattern="[0-9]*"
															placeholder=""
															type="text"
															className="ion-input  sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
															value={dataDeposit?.card_serial}
															onIonChange={(e: any) => {
																e.detail.value = e.detail.value.replace(/[^0-9]/g, "");
																setDataDeposit(
																	defaultsDeep(
																		{
																			card_serial: e.detail.value,
																		},
																		dataDeposit
																	)
																);
															}}
														></IonInput>
													</IonItem>
												</div>
											</IonCol>
											<IonCol size="12" className="ion-col af-wrapper ng-star-inserted ios hydrated">
												<IonButton
													expand="block"
													className="ios button button-block button-solid ion-activatable ion-focusable hydrated btn-naptien"
													onClick={() => _cardDeposit()}
												>
													Nạp Tiền
												</IonButton>
											</IonCol>
										</>
									)}
									{dataDeposit?.type !== "giftcode" && dataDeposit?.type !== "card" && (
										<>
											<IonCol size="12" className="ion-col af-wrapper ng-star-inserted ios hydrated">
												<div className="withdrawal__text--account pb-5">Số Tiền Nạp</div>
												<div className="withdrawal__main--block2">
													<IonItem className="withdrawal__main--item item-interactive item-input item-has-placeholder item ios ion-focusable hydrated ion-untouched ion-pristine ion-valid">
														<IonInput
															inputmode="decimal"
															pattern="[0-9]*"
															placeholder=""
															type="text"
															className="ion-input  sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
															value={dataDeposit?.amount ? formatMoney(toNumber(dataDeposit?.amount)) : ``}
															onIonChange={(e: any) => {
																e.detail.value = e.detail.value.replace(/[^0-9]/g, "");
																setDataDeposit(
																	defaultsDeep(
																		{
																			amount: e.detail.value ? toNumber(e.detail.value) : ``,
																		},
																		dataDeposit
																	)
																);
															}}
															onIonBlur={() => {
																const newAmount = dataDeposit?.amount - (dataDeposit?.amount % 1000);
																setDataDeposit(
																	defaultsDeep(
																		{
																			amount: toNumber(newAmount),
																		},
																		dataDeposit
																	)
																);
															}}
														></IonInput>
														<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
															VNĐ
														</IonText>
													</IonItem>
													{dataDeposit?.type === "usdt" && (
														<div className="note-small pt-5">
															<small>
																Số USDT cần nạp:{" "}
																<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
																	{toNumber(dataDeposit?.amount) > 1000 ? formatMoney(toNumber(dataDeposit?.amount) / 25000) : 0}
																</IonText>{" "}
															</small>
														</div>
													)}
													<div className="note-small pt-5">
														<small>
															Quy đổi:{" "}
															<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
																1,000
															</IonText>{" "}
															VNĐ ={" "}
															<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
																1
															</IonText>{" "}
															điểm
														</small>
													</div>
													<div className="note-small pt-5">
														<small>
															Số điểm thực nhận (
															<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
																{toNumber(dataDeposit?.amount) > 1000 ? formatMoney(toNumber(dataDeposit?.amount) / 1000) : 0}
															</IonText>
															)
														</small>
													</div>
												</div>
											</IonCol>
											<div className="grid grid-cols-6">
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 100000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(100000),
																},
																dataDeposit
															)
														);
													}}
												>
													100K
												</IonButton>
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 200000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(200000),
																},
																dataDeposit
															)
														);
													}}
												>
													200K
												</IonButton>
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 300000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(300000),
																},
																dataDeposit
															)
														);
													}}
												>
													300K
												</IonButton>
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 500000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(500000),
																},
																dataDeposit
															)
														);
													}}
												>
													500K
												</IonButton>
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 1000000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(1000000),
																},
																dataDeposit
															)
														);
													}}
												>
													1.000K
												</IonButton>
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 2000000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(2000000),
																},
																dataDeposit
															)
														);
													}}
												>
													2.000K
												</IonButton>
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 5000000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(5000000),
																},
																dataDeposit
															)
														);
													}}
												>
													5.000K
												</IonButton>
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 10000000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(10000000),
																},
																dataDeposit
															)
														);
													}}
												>
													10.000K
												</IonButton>
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 15000000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(15000000),
																},
																dataDeposit
															)
														);
													}}
												>
													15.000K
												</IonButton>
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 20000000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(20000000),
																},
																dataDeposit
															)
														);
													}}
												>
													20.000K
												</IonButton>
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 30000000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(30000000),
																},
																dataDeposit
															)
														);
													}}
												>
													30.000K
												</IonButton>
												<IonButton
													className={`btn-sic-naptien ${dataDeposit?.amount === 50000000 ? `active` : ``}`}
													size="small"
													onClick={() => {
														setDataDeposit(
															defaultsDeep(
																{
																	amount: toNumber(50000000),
																},
																dataDeposit
															)
														);
													}}
												>
													50.000K
												</IonButton>
											</div>
											<IonCol size="12" className="ion-col af-wrapper ng-star-inserted ios hydrated">
												<IonButton
													expand="block"
													className="ios button button-block button-solid ion-activatable ion-focusable hydrated btn-naptien"
													onClick={() => _depositAutoBank()}
												>
													Nạp Tiền
												</IonButton>
											</IonCol>
										</>
									)}
									{dataDeposit?.type === "giftcode" && (
										<>
											<div className="box-info-vip w-100">
												<div className="row-vip mb-7">
													<span className="title">Nhập mã Giftcode</span>
													<IonInput
														placeholder=""
														type="text"
														className="ion-input ion-input-gift-code"
														value={giftCodeName}
														onIonChange={(e: any) => setGiftCodeName(e.detail.value)}
													></IonInput>
												</div>
												<div className="row-vip justify-content-right">
													<IonButton size="small" className="btn-giftcode" onClick={() => _getGiftCodeByCodeName()}>
														XEM CHI TIẾT
													</IonButton>
												</div>
											</div>
											{infoGiftCode && (
												<>
													<div className="sic-title-page w-100">
														<span> Thông tin Giftcode </span>
														<hr />
													</div>
													<div className="box-info-vip w-100">
														<div className="row-vip mb-7">
															<span className="title">Tên Giftcode</span>
															<div className="text text-black">{infoGiftCode?.CodeName}</div>
														</div>
														{/* <div className="row-vip mb-7">
															<span className="title">Mệnh giá Giftcode</span>
															<div className="text">{infoGiftCode?.AmountRange} K</div>
														</div>
														<div className="row-vip mb-7">
															<span className="title">Loại Giftcode</span>
															<div className="text text-black">{infoGiftCode?.CodeType}</div>
														</div> */}
														{infoGiftCode?.DateEnd && (
															<div className="row-vip mb-7">
																<span className="title">Ngày hết hạn</span>
																<div className="text text-black">{moment(infoGiftCode?.DateEnd).format("DD/MM/YYYY")}</div>
															</div>
														)}
														{/* <div className="row-vip mb-7">
															<span className="title">Sử dụng chung</span>
															<div className="text text-black">{infoGiftCode?.isMultiple ? "Có" : "Không"}</div>
														</div> */}
														{/* {infoGiftCode?.GameProvider && (
															<div className="row-vip mb-7">
																<span className="title">Tại sảnh cược</span>
																<div className="text text-black">
																	{JSON.parse(infoGiftCode?.GameProvider) &&
																		JSON.parse(infoGiftCode?.GameProvider).length > 0 &&
																		JSON.parse(infoGiftCode?.GameProvider)
																			.map((e: any) => e.label)
																			.join(", ")}
																</div>
															</div>
														)} */}
														{infoGiftCode?.BetRound && (
															<div className="row-vip mb-7">
																<span className="title">Vòng cược</span>
																<div className="text text-black">{infoGiftCode?.BetRound}</div>
															</div>
														)}
														<div className="row-vip justify-content-center">
															<IonButton size="small" className="btn-giftcode" onClick={() => _applyGiftCode()}>
																NHẬN GIFTCODE
															</IonButton>
														</div>
													</div>
												</>
											)}
										</>
									)}
									{bankDeposit && bankDeposit.url && (
										<IonCol size="12" className="ion-col af-wrapper ng-star-inserted ios hydrated">
											<iframe
												title="sic-bank-deposit"
												style={{
													width: "100%",
													height: "1650px",
												}}
												frameBorder={0}
												src={bankDeposit?.url}
											></iframe>
										</IonCol>
									)}
									{bankDeposit && dataDeposit.type === "manual" && (
										<>
											<div className="sic-title-page">
												<span> Thông Tin Ngân Hàng VIBET88 </span>
												<hr />
											</div>
											<div className="box-info-vip">
												<div className="row-vip mb-7">
													<span className="title">Tên ngân hàng</span>
													<div className="text">{dataDeposit?.BankName}</div>
													<IonButton
														size="small"
														className="btn-copy-bank-info"
														onClick={() => writeToClipboard(dataDeposit?.BankName, "Copy tên ngân hàng thành công!")}
													>
														Copy
													</IonButton>
												</div>
												<div className="row-vip mb-7">
													<span className="title">Chủ tài khoản</span>
													<div className="text">{dataDeposit?.AccountName}</div>
													<IonButton
														size="small"
														className="btn-copy-bank-info"
														onClick={() => writeToClipboard(dataDeposit?.AccountName, "Copy chủ tài khoản thành công!")}
													>
														Copy
													</IonButton>
												</div>
												<div className="row-vip mb-7">
													<span className="title">Số tài khoản</span>
													<div className="text">{dataDeposit?.AccountNumber}</div>
													<IonButton
														size="small"
														className="btn-copy-bank-info"
														onClick={() => writeToClipboard(dataDeposit?.AccountNumber, "Copy số tài khoản thành công!")}
													>
														Copy
													</IonButton>
												</div>
												<div className="row-vip mb-7">
													<span className="title">Nội dung</span>
													<div className="text">{bankDeposit?.note} </div>
													<IonButton
														size="small"
														className="btn-copy-bank-info"
														onClick={() => writeToClipboard(bankDeposit?.note, "Copy nội dung thành công!")}
													>
														Copy
													</IonButton>
												</div>
												<div className="row-vip mb-7">
													<span className="title">Số tiền nạp</span>
													<div className="text">{formatMoney(dataDeposit?.amount)}</div>
													<IonButton
														size="small"
														className="btn-copy-bank-info"
														onClick={() => writeToClipboard(dataDeposit?.amount, "Copy số tiền nạp thành công!")}
													>
														Copy
													</IonButton>
												</div>
												<div className="row-vip mb-7">
													<span className="title">Mã QR</span>
													<div className="text">{bankDeposit?.qrDataURL && <IonImg className="" src={bankDeposit?.qrDataURL} />}</div>
												</div>
											</div>
										</>
									)}
									{usdtDeposit && dataDeposit.type === "usdt" && (
										<>
											<div className="sic-title-page">
												<span> Thông Tin Địa Chỉ USDT VIBET88 </span>
												<hr />
											</div>
											<div className="box-info-vip">
												<div className="row-vip mb-7">
													<span className="title flex-1">Địa chỉ</span>
													<div className="text w-170">{usdtDeposit?.AccountNumber}</div>
													<IonButton
														size="small"
														className="btn-copy-bank-info"
														onClick={() => writeToClipboard(usdtDeposit?.AccountNumber, "Copy tên địa chỉ thành công!")}
													>
														Copy
													</IonButton>
												</div>
												<div className="row-vip mb-7">
													<span className="title flex-1">Mạng lưới</span>
													<div className="text w-170">BNB Smart Chain (BEP20)</div>
													<IonButton
														size="small"
														className="btn-copy-bank-info"
														onClick={() => writeToClipboard(`BNB Smart Chain (BEP20)`, "Copy mạng lưới thành công!")}
													>
														Copy
													</IonButton>
												</div>
												<div className="row-vip mb-7">
													<span className="title flex-1">Số USDT</span>
													<div className="text w-170">{usdtDeposit?.UsdtAmount}</div>
													<IonButton
														size="small"
														className="btn-copy-bank-info"
														onClick={() => writeToClipboard(`${usdtDeposit?.UsdtAmount}`.replace(".", ","), "Copy số USDT thành công!")}
													>
														Copy
													</IonButton>
												</div>
												<div className="row-vip mb-7">
													<span className="title">Mã QR</span>
													<div className="text">
														<IonImg className="" src="assets/address-usdt.png" />
													</div>
												</div>
											</div>
										</>
									)}
								</IonRow>
							</IonGrid>
						</>
					)}
					{tabDeposit === 1 && (
						<IonList className="ios ion-grid sic-deposit-history vibet88-d-w-history list-ios hydrated">
							{dataDepositHistory.map((item: any, index: any) => {
								return (
									<React.Fragment key={index}>
										<div className={`history-ion-item ${chooseDepositHistory?.index === index ? "active" : ""}`}>
											<div
												className="history-item"
												onClick={() => {
													setChooseDepositHistory(defaultsDeep({ index: index }, item));
												}}
											>
												<div className="flex border-bottom-d-w">
													<div className="flex-1">
														<span className="date text-no-nowrap font-semibold text-2a5b84">{moment(item?.CreatedTime).format("DD-MM-YYYY HH:mm:ss")}</span>
													</div>
													<div className="flex-1 flex justify-between">
														<span className="text-5a5a5a font-semibold">Số tiền nạp:</span>
														<span className="text-black font-semibold">{formatMoneyColor(item?.Amount)} K</span>
													</div>
												</div>
												<div className="flex pt-5">
													<div className="flex-1">
														<span className="text-5a5a5a font-semibold">Mã: </span>
														<span className="num">{formatUUID(item?.Id)}</span>
													</div>
													<div className="flex-1 flex justify-between">
														<span className="text-[14px] font-semibold text-5a5a5a">Trạng thái:</span>
														<span className={item?.approvedFormat === "Đã duyệt" ? "text-2a5b84" : "text-red"}>{item?.approvedFormat}</span>
													</div>
												</div>
											</div>
										</div>
										{chooseDepositHistory?.index === index && (
											<div className="history-type-game">
												<React.Fragment key={`history_${index}`}>
													<div className="game-row">
														<div className="row-type">
															<div className="top">
																<div className="flex gap-5">
																	<span className="font-semibold text-5a5a5a">Hình thức: </span>
																	<span className="">{item?.Note === "AutoBank" ? "AutoBank" : "Chuyển khoản"}</span>
																</div>
															</div>
															<div className="bottom flex-1 flex">
																<div className="flex-1 flex gap-5 items-end">
																	<span className="font-semibold text-5a5a5a">Nội dung chuyển khoản:</span>
																	<span className="">{item?.Note}</span>
																</div>
															</div>
														</div>
													</div>
												</React.Fragment>
											</div>
										)}
									</React.Fragment>
								);
							})}
						</IonList>
					)}
					{tabDeposit === 2 && (
						<>
							<div className="sic-line">
								<hr />
							</div>
							{dataBetCheckingProcessList?.data && size(dataBetCheckingProcessList?.data) > 0 && (
								<div className="rounded-md bg-27619636 m-2 p-2 gap-2 flex flex-col">
									<div className="w-full flex justify-between items-center pb-1">
										<span className="text-[13px] text-black font-bold">Bạn cần hoàn thành đủ Vòng Cược mới có thể rút tiền.</span>
									</div>
									<div className="flex flex-col gap-2 max-h-[80px] w-full overflow-y-scroll overscroll">
										{dataBetCheckingProcessList?.data.map((item: any, index: any) => {
											if (isWithdrawal && item?.BetCheckingStatus !== "done") {
												setIsWithdrawal(false);
											}
											return (
												<div className="flex gap-2 items-center w-full" key={index}>
													<span className="bg-white text-black text-[12px] font-bold rounded-md px-2 h-5 flex items-center">{item?.Title}</span>
													<div className="relative flex-1 flex">
														<ProgressBar
															className="flex-1"
															completed={item?.TotalBet}
															bgColor="#F5BC27"
															baseBgColor="#FFFFFF"
															maxCompleted={item?.TotalBetNeed}
															isLabelVisible={false}
														/>
														<div className="absolute flex items-center right-1 top-0 bottom-0 my-auto text-[10px]">
															Đã cược &nbsp;<span className="text-[#FF0000] text-[10px] font-bold">{formatMoney(item?.TotalBet)}&nbsp;K</span>&nbsp;-&nbsp;Cần
															hoàn thành&nbsp;
															<span className="text-[#2A5B84] text-[10px] font-bold">{formatMoney(subtract(item?.TotalBetNeed, item?.TotalBet))}&nbsp;K</span>
															&nbsp;/&nbsp;
															<span className="text-black text-[10px] font-bold">{formatMoney(item?.TotalBetNeed)}&nbsp;K</span>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							)}
							<div className="btn-group">
								<IonButton
									className={`btn-sic btn-tab-naptien ${typeWithdrawal === "bank" ? `active` : ``}`}
									size="small"
									onClick={() => {
										setTypeWithdrawal("bank");
										setBankDeposit(null);
										setUsdtDeposit(null);
										setDataWithdraw(INIT_WITHDRAW);
									}}
								>
									Ngân Hàng
								</IonButton>
								<IonButton
									className={`btn-sic btn-tab-naptien ${typeWithdrawal === "usdt" ? `active` : ``}`}
									size="small"
									onClick={() => {
										setTypeWithdrawal("usdt");
										setBankDeposit(null);
										setUsdtDeposit(null);
										setDataWithdraw(INIT_WITHDRAW);
									}}
								>
									<IonImg className="w-22 pr-5" slot="start" src={`${IMG_MB}assets/icon/icon-usdt.png`}></IonImg>
									USDT
								</IonButton>
							</div>
							<header className="withdrawal__header">
								<section className="withdrawal__header--block2">
									<div className="withdrawal__text--black">Số điểm có thể rút:</div>
									<div>
										<span className="withdrawal__text--money">{`${formatMoney(userInfo?.Credits)} K`}</span>
									</div>
								</section>
							</header>
							<main className="withdrawal__main">
								{typeWithdrawal === "bank" && (
									<>
										<div className="withdrawal__text--account pb-5">Ngân hàng nhận tiền</div>
										<div className="withdrawal__main--block1">
											<IonItem lines="none" className="ion-item">
												<IonSelect
													className="ion-select"
													cancelText="Huỷ"
													mode="ios"
													okText="Chọn"
													role="combobox"
													aria-haspopup="dialog"
													aria-expanded="false"
													aria-labelledby="ion-sel-19-lbl"
													value={dataWithdraw.IndexBank}
													onIonChange={(e) => {
														setDataWithdraw(
															defaultsDeep(
																{
																	BankName: get(userBanks, `[${e.detail.value}].BankName`, ``),
																	AccountName: get(userBanks, `[${e.detail.value}].AccountName`, ``),
																	AccountNumber: get(userBanks, `[${e.detail.value}].AccountNumber`, ``),
																	IndexBank: e.detail.value,
																},
																dataWithdraw
															)
														);
													}}
												>
													{userBanks &&
														userBanks.length > 0 &&
														userBanks.map((bank: any, index: any) => {
															return (
																<IonSelectOption key={index} value={`${index}`} role="option" id="ion-selopt-441" className="ios hydrated">
																	{`${bank.AccountName} (${bank.AccountNumber} - ${bank.BankName})`}
																</IonSelectOption>
															);
														})}
												</IonSelect>
											</IonItem>
										</div>
									</>
								)}
								{userInfo?.UsdtWallet && typeWithdrawal === "usdt" && (
									<div className="withdrawal__main--block2">
										<div className="withdrawal__text--account pt-5 pb-5">Địa chỉ USDT của bạn</div>
										<IonItem className="withdrawal__main--item item-interactive item-input item-has-placeholder item ios ion-focusable hydrated ion-untouched ion-pristine ion-valid">
											<IonInput
												type="text"
												value={userInfo?.UsdtWallet}
												className="ion-input text-14px text-normal ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
												readonly
											></IonInput>
										</IonItem>
									</div>
								)}
								{!userInfo?.UsdtWallet && typeWithdrawal === "usdt" && (
									<div className="withdrawal__main--block2">
										<div className="withdrawal__text--account pt-5 pb-5">Nhập địa chỉ USDT của bạn</div>
										<IonItem className="withdrawal__main--item item-interactive item-input item-has-placeholder item ios ion-focusable hydrated ion-untouched ion-pristine ion-valid">
											<IonInput
												type="text"
												value={dataWithdraw.AccountNumber}
												className="ion-input text-14px text-normal ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
												onIonChange={(e: any) => {
													setDataWithdraw(
														defaultsDeep(
															{
																AccountNumber: e.detail.value,
															},
															dataWithdraw
														)
													);
												}}
											></IonInput>
										</IonItem>
									</div>
								)}
								{typeWithdrawal === "usdt" && (
									<div className="withdrawal__main--block2">
										<div className="withdrawal__text--account pt-5 pb-5">Mạng lưới</div>
										<IonItem className="withdrawal__main--item item-interactive item-input item-has-placeholder item ios ion-focusable hydrated ion-untouched ion-pristine ion-valid">
											<IonInput
												type="text"
												value={`BNB Smart Chain (BEP20)`}
												className="ion-input text-14px text-normal ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
												readonly
											></IonInput>
										</IonItem>
									</div>
								)}
								<div className="withdrawal__main--block2">
									<div className="withdrawal__text--account pt-5 pb-5">Số tiền rút</div>
									<IonItem className="withdrawal__main--item item-interactive item-input item-has-placeholder item ios ion-focusable hydrated ion-untouched ion-pristine ion-valid">
										<IonInput
											inputmode="decimal"
											pattern="[0-9]*"
											placeholder="Nhập số tiền"
											type="text"
											value={dataWithdraw.Amount ? formatMoney(toNumber(dataWithdraw.Amount)) : ``}
											className="ion-input ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
											onIonChange={(e: any) => {
												e.detail.value = e.detail.value.replace(/[^0-9]/g, "");
												setDataWithdraw(
													defaultsDeep(
														{
															Amount: e.detail.value,
														},
														dataWithdraw
													)
												);
											}}
										></IonInput>
										<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
											VNĐ
										</IonText>
									</IonItem>
									<div className="note-small pt-5">
										<small>
											<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
												* Số tiền rút từ 200k.
											</IonText>
										</small>
									</div>
									<div className="note-small pt-5 pb-5">
										<small>
											Số điểm trừ (
											<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
												{toNumber(dataWithdraw?.Amount) > 1000 ? formatMoney(Math.floor(toNumber(dataWithdraw?.Amount) / 1000)) : 0}
											</IonText>
											)
										</small>
									</div>
									{typeWithdrawal === "usdt" && (
										<div className="note-small pt-5 pb-5">
											<small>
												Số USDT nhận (
												<IonText color="danger" className="ion-color ion-color-primary ios hydrated">
													{toNumber(dataWithdraw?.Amount) > 1000 ? formatMoney(Math.floor(toNumber(dataWithdraw?.Amount) / 25000)) : 0}
												</IonText>
												)
											</small>
										</div>
									)}
								</div>
								<div className="withdrawal__main--block2 pb-5">
									<div className="withdrawal__text--account pt-5 pb-5">Mã rút tiền</div>
									<IonItem className="withdrawal__main--item item-interactive item-input item-has-placeholder item ios ion-focusable hydrated ion-untouched ion-pristine ion-valid">
										<div className="flex">
											<IonInput
												type={isShowPass ? "text" : "password"}
												value={dataWithdraw.WithdrawPassword}
												className="ion-input ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
												onIonChange={(e: any) => {
													setDataWithdraw(
														defaultsDeep(
															{
																WithdrawPassword: e.detail.value,
															},
															dataWithdraw
														)
													);
												}}
											></IonInput>
											<IonIcon className="icon--eye" icon={eye} onClick={() => setIsShowPass(!isShowPass)} />
										</div>
									</IonItem>
								</div>
								<IonButton
									expand="block"
									className={`ios button button-block button-solid ion-activatable ion-focusable hydrated btn-naptien ${isWithdrawal ? "" : "opacity-60"}`}
									onClick={() => WithdrawBank()}
								>
									Rút Tiền
								</IonButton>
							</main>
						</>
					)}
					{tabDeposit === 3 && (
						<IonList className="ios ion-grid sic-deposit-history vibet88-d-w-history list-ios hydrated">
							{dataWithdrawtHistory.map((item: any, index: any) => {
								return (
									<React.Fragment key={index}>
										<div className={`history-ion-item ${chooseDepositHistory?.index === index ? "active" : ""}`}>
											<div
												className="history-item"
												onClick={() => {
													setChooseDepositHistory(defaultsDeep({ index: index }, item));
												}}
											>
												<div className="flex border-bottom-d-w">
													<div className="flex-1">
														<span className="date text-no-nowrap font-semibold text-2a5b84">{moment(item?.CreatedTime).format("DD-MM-YYYY HH:mm:ss")}</span>
													</div>
													<div className="flex-1 flex justify-between">
														<span className="text-5a5a5a font-semibold">Số tiền rút:</span>
														<span className="text-black font-semibold">{formatMoneyColor(item?.Amount)} K</span>
													</div>
												</div>
												<div className="flex pt-5">
													<div className="flex-1">
														<span className="text-5a5a5a font-semibold">Mã: </span>
														<span className="num">{formatUUID(item?.Id)}</span>
													</div>
													<div className="flex-1 flex justify-between">
														<span className="text-[14px] font-semibold text-5a5a5a">Trạng thái:</span>
														<span className={item?.approvedFormat === "Đã duyệt" ? "text-2a5b84" : "text-red"}>{item?.approvedFormat}</span>
													</div>
												</div>
											</div>
										</div>
										{chooseDepositHistory?.index === index && (
											<div className="history-type-game">
												<React.Fragment key={`history_${index}`}>
													<div className="game-row">
														<div className="row-type">
															<div className="top">
																<div className="flex gap-5">
																	<span className="font-semibold text-5a5a5a">Hình thức: </span>
																	<span className="">Chuyển khoản ngân hàng</span>
																</div>
															</div>
															<div className="bottom flex-1 flex">
																<div className="flex-1 flex gap-5 items-end">
																	<span className="font-semibold text-5a5a5a">Số tài khoản:</span>
																	<span className="">
																		{item?.BankName} - {item?.AccountName} - {item?.AccountNumber}
																	</span>
																</div>
															</div>
														</div>
													</div>
												</React.Fragment>
											</div>
										)}
									</React.Fragment>
								);
							})}
						</IonList>
					)}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Deposit;
